<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'ObtainEvidence',

  data () {
    return {
    }
  },

  methods: {}
}
</script>

<style lang='scss' scoped>

</style>
